import React from 'react';
import { connect } from 'react-redux';
import { deleteTesAllStudentAnswer } from './redux/reduxApi';
import { closeModal } from '../../../app/modals/redux/modalActions';
import Modal from '../../../ui/Modal';
import Button from '../../../ui/Button';

const actions = {
  closeModal,
  deleteTesAllStudentAnswer,
};

function ModalDeleteAllStudentAnswer({
  data,
  deleteTesAllStudentAnswer,
  closeModal,
}) {
  const { auth, history, item } = data;

  function onDelete() {
    deleteTesAllStudentAnswer(auth, history, item.tesId);
    closeModal();
  }
  return (
    <Modal>
      <p className='my-3 mx-1'>
        Apakah Anda yakin ingin menghapus tes yang disubmit siswa?
      </p>
      <div className='flex justify-end align-center pt-2'>
        <div className='buttons'>
          <Button cvariant='cdelete' onClick={onDelete} />
          <Button cvariant='cback' onClick={closeModal} />
        </div>
      </div>
    </Modal>
  );
}

export default connect(null, actions)(ModalDeleteAllStudentAnswer);
