import {
  asyncActionFinish,
  asyncActionError,
} from '../../../../app/common/reduxs/async/asyncActions';
import {
  menuwebsGet,
  categoryOptionsGet,
  pageOptionsGet,
  menuwebAdd,
  menuwebEdit,
  menuwebGet,
  menuwebDelete,
} from './reduxAction';
import { ASYNC_ACTION_START } from '../../../../app/common/reduxs/async/asyncConstant';
import { checkErr } from '../../../../app/common/helpers/checkRes';
import { fetchFunc } from '../../../../app/common/helpers/fetchHelpers';
import { logsFunc } from '../../../../app/common/helpers/othersHelpers';

export const getMenuwebs = (
  auth,
  history,
  itemPerPage,
  currentPage,
  searchText
) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'getMenuwebs' });
    const formData = new FormData();
    formData.append('itemPerPage', itemPerPage ? itemPerPage : 10);
    formData.append('currentPage', currentPage);
    formData.append('searchText', searchText);
    try {
      const res = await fetchFunc(
        'gets',
        auth,
        history,
        'menuweb/',
        formData,
        dispatch
      );
      const items = res.items;
      const total = res.total;
      const search = {
        currentPage,
        searchText,
        itemPerPage: itemPerPage ? itemPerPage : 10,
      };
      dispatch(menuwebsGet({ total, items, search }));
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const getCategoryOptions = (auth, history) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'getCategoryOptions' });
    const formData = new FormData();
    formData.append('status', 1);
    try {
      const res = await fetchFunc(
        'get',
        auth,
        history,
        'category/all',
        formData,
        dispatch
      );
      const categories = res.categories;
      dispatch(categoryOptionsGet(categories));
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const getPageOptions = (auth, history) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'getPageOptions' });
    const formData = new FormData();
    formData.append('status', 1);
    try {
      const res = await fetchFunc(
        'get',
        auth,
        history,
        'page/all',
        formData,
        dispatch
      );
      const pages = res.pages;
      dispatch(pageOptionsGet(pages));
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const addMenuweb = (auth, history, values) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'addMenuweb' });
    let logs = logsFunc('create', auth.userid, auth.username, null);
    const formData = new FormData();
    formData.append('menu', values.menu);
    formData.append('category', values.category ? values.category : undefined);
    formData.append('icon', values.icon ? values.icon : undefined);
    formData.append('checkSubmenu', values.checkSubmenu);
    if (values.checkSubmenu) {
      formData.append('submenu', JSON.stringify(values.submenu));
    } else {
      formData.append('menuUrl', values.menuUrl ? values.menuUrl : undefined);
    }
    formData.append('logs', logs);
    try {
      const res = await fetchFunc(
        'add',
        auth,
        history,
        'menuweb/add/',
        formData,
        dispatch
      );
      const menuweb = res.menuweb;
      dispatch(menuwebAdd(menuweb));
      history.push('/menuweb/' + menuweb.id);
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const getMenuweb = (auth, history, id) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'getMenuweb' });
    const formData = new FormData();
    try {
      const res = await fetchFunc(
        'get',
        auth,
        history,
        'menuweb/detail/' + id,
        formData,
        dispatch
      );
      const menuweb = res.menuweb;
      dispatch(menuwebGet(menuweb));
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const editMenuweb = (auth, history, values) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'editMenuweb' });
    let logs = logsFunc('edit', auth.userid, auth.username, values.logs);
    const formData = new FormData();
    formData.append('menu', values.menu);
    formData.append('category', values.category ? values.category : undefined);
    formData.append('icon', values.icon ? values.icon : undefined);
    formData.append('checkSubmenu', values.checkSubmenu);
    if (values.checkSubmenu) {
      formData.append('submenu', JSON.stringify(values.submenu));
    } else {
      formData.append('menuUrl', values.menuUrl ? values.menuUrl : undefined);
    }
    formData.append('logs', logs);
    try {
      const res = await fetchFunc(
        'edit',
        auth,
        history,
        'menuweb/edit/' + values.id,
        formData,
        dispatch
      );
      const menuweb = res.menuweb;
      dispatch(menuwebEdit(menuweb));
      history.push('/menuweb/' + values.id);
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const statusMenuweb = (auth, history, values) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'statusMenuweb' });
    const formData = new FormData();
    formData.append('status', values.status);
    try {
      const res = await fetchFunc(
        'edit',
        auth,
        history,
        'menuweb/status/' + values.id,
        formData,
        dispatch
      );
      const menuweb = res.menuweb;
      dispatch(menuwebEdit(menuweb));
      history.push('/menuweb/' + values.id);
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const deleteMenuweb = (auth, history, id) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'deleteMenuweb' });
    const formData = new FormData();
    try {
      const res = await fetchFunc(
        'delete',
        auth,
        history,
        'menuweb/delete/' + id,
        formData,
        dispatch
      );
      const menuweb = res.menuweb;
      dispatch(menuwebDelete(menuweb));
      history.push('/menuweb');
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};
