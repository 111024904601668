import {
  MENUWEBS_GET,
  MENUWEB_CATEGORYOPTS_GET,
  MENUWEB_PAGEOPTS_GET,
  MENUWEB_ADD,
  MENUWEB_EDIT,
  MENUWEB_GET,
  MENUWEB_DELETE,
} from './reduxConstant';
import { createReducer } from '../../../../app/common/utilities/reducerUtils';

const initialState = {
  total: 0,
  search: {
    currentPage: '',
    searchText: '',
    itemPerPage: '',
  },
  items: [],
  categoryOptions: [],
  pageOptions: [],
};

const menuwebsGet = (state, payload) => {
  const menuwebs = payload.menuwebs;
  return {
    total: menuwebs.total,
    items: [...menuwebs.items],
    search: { ...menuwebs.search },
  };
};

const categoryOptsGet = (state, payload) => {
  const categoryOptsRaw = payload.categoryOpts;
  let categoryOpts = [];
  if (categoryOptsRaw.length) {
    categoryOptsRaw.forEach((i) => {
      categoryOpts.push({ key: i.id, text: i.title, value: i.title });
    });
  }
  return {
    ...state,
    categoryOptions: categoryOpts,
  };
};

const pageOptsGet = (state, payload) => {
  const pageOptsRaw = payload.pageOpts;
  let pageOpts = [{ key: '00001', text: '/', value: '/' }];
  if (pageOptsRaw.length) {
    pageOptsRaw.forEach((i) => {
      pageOpts.push({ key: i.id, text: `/${i.url}`, value: `/${i.url}` });
    });
  }
  return {
    ...state,
    pageOptions: pageOpts,
  };
};

const menuwebAdd = (state, payload) => {
  return {
    total: parseInt(state.total) + 1,
    items: [
      ...state.items.filter((i) => i.id + '' !== payload.menuweb.id + ''),
      payload.menuweb,
    ],
    search: { ...state.search },
  };
};

const menuwebEdit = (state, payload) => {
  return {
    total: parseInt(state.total),
    items: [
      ...state.items.filter((i) => i.id + '' !== payload.menuweb.id + ''),
      payload.menuweb,
    ],
    search: { ...state.search },
  };
};

const menuwebGet = (state, payload) => {
  return {
    total: parseInt(state.total),
    items: [
      ...state.items.filter((i) => i.id + '' !== payload.menuweb.id + ''),
      payload.menuweb,
    ],
    search: { ...state.search },
  };
};

const menuwebDelete = (state, payload) => {
  return {
    total: parseInt(state.total) - 1,
    items: [
      ...state.items.filter((i) => i.id + '' !== payload.menuweb.id + ''),
    ],
    search: { ...state.search },
  };
};

export default createReducer(initialState, {
  [MENUWEBS_GET]: menuwebsGet,
  [MENUWEB_CATEGORYOPTS_GET]: categoryOptsGet,
  [MENUWEB_PAGEOPTS_GET]: pageOptsGet,
  [MENUWEB_ADD]: menuwebAdd,
  [MENUWEB_EDIT]: menuwebEdit,
  [MENUWEB_GET]: menuwebGet,
  [MENUWEB_DELETE]: menuwebDelete,
});
