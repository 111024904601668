import { combineReducers } from 'redux';
import { reducer as toastrReducer } from 'react-redux-toastr';
import asyncReducer from '../common/reduxs/async/asyncReducer';
import { persistReducer } from 'redux-persist';
import storageSession from 'redux-persist/lib/storage/session';
import modalReducer from '../modals/redux/modalReducer';
import sessionStateReducer from '../common/reduxs/session/reduxReducer';
import authReducer from '../login/redux/authReducer';
import { reducer as formReducer } from 'redux-form';
import menusReducer from '../common/reduxs/menu/menusReducer';
import dashboardReducer from '../../menus/dashboard/redux/reduxReducer';
import userReducer from '../../menus/users/redux/reduxReducer';
import roleReducer from '../../menus/roles/redux/reduxReducer';
import searchFormReducer from '../common/reduxs/search/reduxReducer';
import categoryReducer from '../../menus/_features/category/redux/reduxReducer';
import menuwebReducer from '../../menus/_features/menuweb/redux/reduxReducer';
import pageReducer from '../../menus/_features/page/redux/reduxReducer';
import rppReducer from '../../menus/_features/rpp/redux/reduxReducer';
import materiReducer from '../../menus/_features/materi/redux/reduxReducer';
import tugasReducer from '../../menus/_features/tugas/redux/reduxReducer';
import latihanReducer from '../../menus/_features/latihan/redux/reduxReducer';
import monitoringReducer from '../../menus/_features/monitoring/redux/reduxReducer';
import cbtparticipantReducer from '../../menus/_features/cbt/participant/redux/reduxReducer';
import cbtquestionReducer from '../../menus/_features/cbt/question/redux/reduxReducer';
import tesReducer from '../../menus/_features/tes/redux/reduxReducer';
import whatsappReducer from '../../menus/_features/whatsapp/redux/reduxReducer';
import pesanpribadiReducer from '../../menus/_features/pesanpribadi/redux/reduxReducer';
import slideshowReducer from '../../menus/_features/slideshow/redux/reduxReducer';
import announcementReducer from '../../menus/_features/announcement/redux/reduxReducer';
import calendarReducer from '../../menus/_features/calendar/redux/reduxReducer';
import portaluserReducer from '../../menus/_features/portaluser/redux/reduxReducer';
import jenjangReducer from '../../menus/_features/jenjang/redux/reduxReducer';
import tingkatReducer from '../../menus/_features/tingkat/redux/reduxReducer';
import kelasReducer from '../../menus/_features/kelas/redux/reduxReducer';
import pelajaranReducer from '../../menus/_features/pelajaran/redux/reduxReducer';
import yearReducer from '../../menus/_features/year/redux/reduxReducer';
import semReducer from '../../menus/_features/sem/redux/reduxReducer';
import settingReducer from '../../menus/_features/setting/redux/reduxReducer';

const persistConfig = {
  key: 'root',
  storage: storageSession,
};

const appReducer = combineReducers({
  auth: authReducer,
  form: formReducer,
  menus: menusReducer,
  toastr: toastrReducer,
  async: asyncReducer,
  modals: modalReducer,
  sessionState: sessionStateReducer,
  dashboard: dashboardReducer,
  users: userReducer,
  roles: roleReducer,
  searchForm: searchFormReducer,
  categories: categoryReducer,
  menuwebs: menuwebReducer,
  pages: pageReducer,
  rpps: rppReducer,
  materis: materiReducer,
  tugass: tugasReducer,
  latihans: latihanReducer,
  tess: tesReducer,
  monitoring: monitoringReducer,
  cbtparticipants: cbtparticipantReducer,
  cbtquestions: cbtquestionReducer,
  whatsapps: whatsappReducer,
  pesanpribadis: pesanpribadiReducer,
  slideshows: slideshowReducer,
  announcements: announcementReducer,
  calendars: calendarReducer,
  portalusers: portaluserReducer,
  jenjangs: jenjangReducer,
  tingkats: tingkatReducer,
  kelass: kelasReducer,
  pelajarans: pelajaranReducer,
  years: yearReducer,
  sems: semReducer,
  setting: settingReducer,
});

const initialState = appReducer({}, {});

const rootReducer = (state, action) => {
  if (action.type === 'AUTH_LOGOUT') {
    state = initialState;
  }
  return appReducer(state, action);
};

export default persistReducer(persistConfig, rootReducer);
