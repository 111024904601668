import {
  CATEGORIES_GET,
  CATEGORY_ADD,
  CATEGORY_EDIT,
  CATEGORY_GET,
  CATEGORY_DELETE,
} from './reduxConstant';

export const categoriesGet = (categories) => {
  return {
    type: CATEGORIES_GET,
    payload: {
      categories,
    },
  };
};

export const categoryAdd = (category) => {
  return {
    type: CATEGORY_ADD,
    payload: {
      category,
    },
  };
};

export const categoryEdit = (category) => {
  return {
    type: CATEGORY_EDIT,
    payload: {
      category,
    },
  };
};

export const categoryGet = (category) => {
  return {
    type: CATEGORY_GET,
    payload: {
      category,
    },
  };
};

export const categoryDelete = (category) => {
  return {
    type: CATEGORY_DELETE,
    payload: {
      category,
    },
  };
};
