import React from 'react';
import { connect, useDispatch } from 'react-redux';
import TextInput from '../../app/common/form/TextInput';
import { reduxForm, Field, reset } from 'redux-form';
import { useHistory } from 'react-router-dom';
import {
  composeValidators,
  combineValidators,
  isRequired,
  hasLengthGreaterThan,
  createValidator,
} from 'revalidate';
import { toastr } from 'react-redux-toastr';
import Icon from '@mdi/react';
import { mdiLockReset } from '@mdi/js';
import Button from '../../ui/Button';

function mapState(state) {
  let scope = {};
  const auth = state.auth;
  if (auth && auth.isAuth) {
    scope = auth.authorities;
  }
  return {
    auth,
    scope,
    loading: state.async.loading,
  };
}

let newPass;
const handleOnFormChange = (newValues, dispatch, props, previousValues) => {
  const { newPassword: newNewPass } = newValues;
  newPass = newNewPass;
};

const isPassEqual = createValidator(
  (message) => (value) => {
    if (value && value !== newPass) {
      return message;
    }
  },
  'Password tidak sama!'
);

const isAlphaNum = createValidator(
  (message) => (value) => {
    if (value && !value.match(/^[0-9a-z]+$/)) {
      return message;
    }
  },
  'Password karakter harus huruf atau angka'
);

const validate = combineValidators({
  newPassword: composeValidators(
    isRequired({ message: 'Password baru harus diisi' }),
    hasLengthGreaterThan(3)({
      message: 'Password harus memiliki paling sedikit 4 karakter',
    }),
    isAlphaNum
  )(),
  confirmPassword: composeValidators(
    isRequired({ message: 'Konfirmasi password harus diisi' }),
    isPassEqual
  )(),
});

function MResetPasswordForm({
  auth,
  invalid,
  loading,
  pristine,
  handleSubmit,
  resetPassword,
  closeModal,
}) {
  const dispatch = useDispatch();
  let history = useHistory();

  const onFormSubmit = (values) => {
    resetPassword(auth, history, values).then((res) => {
      if (!res) {
        return;
      }
      if (res.status === 'success') {
        dispatch(reset('changePass'));
      }
      closeModal();
      toastr.success('Sukses', `Update password ${res.user.username} berhasil`);
    });
  };

  return (
    <section className='modal-card-body is-size-6'>
      <div className='columns'>
        <div className='column is-third-quarter is-capitalized'>
          <div className='is-size-4 has-text-weight-bold has-text-info pb-4 flex justify-center align-center'>
            <Icon path={mdiLockReset} size={1} />
            <span className='ml-2'>Reset Password</span>
          </div>
          <form onSubmit={handleSubmit(onFormSubmit)} autoComplete='off'>
            <Field
              name='username'
              type='text'
              component={TextInput}
              placeholder='Username'
              label='Username'
              fullwidth={true}
              readOnly={true}
            />
            <Field
              name='newPassword'
              type='password'
              component={TextInput}
              placeholder='Password Baru'
              label='Password Baru'
              fullwidth={true}
            />
            <Field
              name='confirmPassword'
              type='password'
              component={TextInput}
              placeholder='Konfirm Password'
              label='Konfirm Password'
              fullwidth={true}
            />
          </form>
        </div>
      </div>
      <div className='flex justify-end align-center'>
        <div className='buttons'>
          <Button
            cvariant='csave'
            onClick={handleSubmit(onFormSubmit)}
            disabled={invalid || loading || pristine}
          />
          <Button cvariant='cback' onClick={closeModal} />
        </div>
      </div>
    </section>
  );
}

export default connect(
  mapState,
  null
)(
  reduxForm({
    form: 'userResetPassword',
    onChange: handleOnFormChange,
    validate,
    enableReinitialize: true,
  })(MResetPasswordForm)
);
