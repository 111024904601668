import React, { useState, useEffect } from 'react';
import { reduxForm, Field } from 'redux-form';
import SelectInput from '../../../app/common/form/SelectInput';
import MSyncScoreFormList from './MSyncScoreFormList';
import Button from '../../../ui/Button';

function MSyncScoreForm(props) {
  const {
    auth,
    history,
    id,
    item,
    scores,
    optionKelas,
    detailScores,
    listScores,
    getDetailTugasScores,
    getListTugasScores,
    sendListTugasScores,
    handleSubmit,
    closeModal,
    loading,
  } = props;
  const [state, setState] = useState({
    kelas: '',
    syncDetail: '',
    typeScoring: '',
    detailScoring: '',
  });
  let DETAIL_NILAI = detailScores && detailScores.DETAIL_NILAI;
  let detailNilai = [];
  if (DETAIL_NILAI) {
    for (let i = 0; i < DETAIL_NILAI.length; i++) {
      let item = DETAIL_NILAI[i];
      let newItem = {
        ...item,
        key: item.JENIS_NILAI + ';' + item.DETAIL_NILAI,
        text: item.JENIS_NILAI + ' - ' + item.DETAIL_NILAI,
        value: item.JENIS_NILAI + ';' + item.DETAIL_NILAI,
      };
      detailNilai = [...detailNilai, newItem];
    }
  }

  useEffect(() => {
    let kelas = optionKelas && optionKelas[0] && optionKelas[0].text;
    setState({ ...state, kelas: kelas });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let kelas = state.kelas;
    let newValues = {
      ...item,
      kelas: kelas,
    };
    if (kelas) {
      getDetailTugasScores(auth, history, id, newValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.kelas]);

  useEffect(() => {
    if (detailNilai && state.typeScoring && state.detailScoring) {
      let newValues = {
        idx: detailScores.IDX_LEMBAR_NILAI,
        typeScoring: state.typeScoring,
        detailScoring: state.detailScoring,
      };
      getListTugasScores(auth, history, id, newValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.syncDetail]);

  const onChangeSelect = (e, name) => {
    e.preventDefault();
    let value = e.target.value;
    if (name === 'syncDetail') {
      setState({
        ...state,
        [name]: value,
        typeScoring: value.split(';')[0],
        detailScoring: value.split(';')[1],
      });
    } else {
      setState({
        ...state,
        [name]: value,
        syncDetail: '',
        typeScoring: '',
        detailScoring: '',
      });
    }
  };

  const onSyncScore = () => {
    let rowScores = [];
    for (let i = 0; i < listScores.length; i++) {
      const item = listScores[i];
      const score = scores.find((it) => it.nis + '' === item.NIS + '');
      let pushScore = 0;
      if (!score) {
        pushScore = 0;
      } else if (score && score?.nis && item.NIS + '' === score?.nis) {
        let roundNumb = !isNaN(score.finalScore)
          ? Math.round(score.finalScore)
          : 0;
        pushScore = roundNumb;
      } else {
        pushScore = 0;
      }
      rowScores = [...rowScores, pushScore];
    }
    let newRowSCores = rowScores.join(';');
    let newItem = {
      idx: detailScores.IDX_LEMBAR_NILAI,
      typeScoring: state.typeScoring,
      detailScoring: state.detailScoring,
      rowScores: newRowSCores,
      kelas: state.kelas,
    };
    sendListTugasScores(auth, history, id, newItem);
    closeModal();
  };

  return (
    <form onSubmit={handleSubmit(onSyncScore)} autoComplete='off'>
      <div className='flex justify-between'>
        <div>
          <div className='is-size-5 has-text-weight-bold mb-2 has-text-info'>
            Sync Nilai
          </div>
          <div className='mb-3'>
            <span className='tag is-rounded is-info is-light mr-2'>
              {item.pelajaran}
            </span>
            <span className='tag is-rounded is-info is-light mr-2'>
              {item.year}
            </span>
            <span className='tag is-rounded is-info is-light mr-2'>
              {item.sem}
            </span>
          </div>
        </div>
        <div className='flex justify-end'>
          <div>
            <span>
              <Button
                type='submit'
                variant='save'
                disabled={
                  loading || !(listScores && listScores[0]) || !state.syncDetail
                }
              />
            </span>
            <span className='ml-1'>
              <Button variant='back' onClick={closeModal} />
            </span>
          </div>
        </div>
      </div>
      <div className='field is-horizontal'>
        <div className='field-body py-2'>
          <Field
            name='kelas'
            type='text'
            component={SelectInput}
            onChange={(e) => onChangeSelect(e, 'kelas')}
            defaultValue={state.kelas ?? ''}
            placeholder='Kelas'
            label='Kelas'
            options={optionKelas}
            fullwidth={true}
          />
          <Field
            name='syncDetail'
            type='text'
            component={SelectInput}
            onChange={(e) => onChangeSelect(e, 'syncDetail')}
            defaultValue={state.syncDetail ?? ''}
            placeholder='Detail Penilaian'
            label='Detail Penilaian'
            options={detailNilai}
            fullwidth={true}
          />
        </div>
      </div>
      {state.syncDetail && listScores && listScores[0] && (
        <div className='mt-4'>
          <label className='label' htmlFor='table'>
            Tabel Sync Nilai
          </label>
          <div className='overflow-auto'>
            <div className='c-table'>
              <table className='table is-bordered is-fullwidth is-hoverable is-narrow'>
                <thead>
                  <tr>
                    <th className='has-text-centered' style={{ width: 50 }}>
                      No
                    </th>
                    <th className='has-text-centered' style={{ width: 200 }}>
                      NIS
                    </th>
                    <th className='has-text-centered' style={{ width: 400 }}>
                      Nama
                    </th>
                    <th className='has-text-centered'>
                      Nilai {state.detailScoring || '...'}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <MSyncScoreFormList scores={scores} listScores={listScores} />
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
    </form>
  );
}

export default reduxForm({
  form: 'syncTugasScore',
  enableReinitialize: true,
})(MSyncScoreForm);
