import {
  PAGES_GET,
  PAGE_CATEGORYOPTS_GET,
  PAGE_ADD,
  PAGE_EDIT,
  PAGE_UPLOADFILE,
  PAGE_GET,
  PAGE_DELETE,
} from './reduxConstant';

export const pagesGet = (pages) => {
  return {
    type: PAGES_GET,
    payload: {
      pages,
    },
  };
};

export const categoryOptionsGet = (categoryOpts) => {
  return {
    type: PAGE_CATEGORYOPTS_GET,
    payload: {
      categoryOpts,
    },
  };
};

export const pageAdd = (page) => {
  return {
    type: PAGE_ADD,
    payload: {
      page,
    },
  };
};

export const pageEdit = (page) => {
  return {
    type: PAGE_EDIT,
    payload: {
      page,
    },
  };
};

export const pageUploadFile = (status) => {
  return {
    type: PAGE_UPLOADFILE,
    payload: {
      status,
    },
  };
};

export const pageGet = (page) => {
  return {
    type: PAGE_GET,
    payload: {
      page,
    },
  };
};

export const pageDelete = (page) => {
  return {
    type: PAGE_DELETE,
    payload: {
      page,
    },
  };
};
