import React from 'react';
import { withRouter, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { combineValidators, isRequired } from 'revalidate';
import { reduxForm, Field } from 'redux-form';
import { loginAuth } from './redux/authApi';
import LOGO_SCHOOL from '../../pictures/logo_school2.png';
import LoginTextInput from '../common/form/LoginTextInput';

function mapState(state) {
  return {
    auth: state.auth,
    loading: state.async.loading,
  };
}

const actions = {
  loginAuth,
};

const validate = combineValidators({
  username: isRequired({ message: 'Username is required' }),
  password: isRequired({ message: 'Password is required' }),
});

function Login(props) {
  const { loginAuth, handleSubmit, auth, error, loading } = props;

  const handleLogin = async (authData) => {
    await loginAuth(authData);
  };

  if (auth && auth.isAuth) return <Redirect to='/dashboard' />;

  return (
    <>
      <section
        className='hero is-fullheight gradient-login'
        style={{ marginTop: -52 }}
      >
        <div className='hero-body'>
          <div className='container'>
            <div className='columns is-centered is gapless'>
              <div className='column is-6-tablet is-6-desktop is-5-widescreen'>
                <div className='box'>
                  <div className='columns'>
                    {/* <div className='column is-4-tablet is-3-desktop custom-background-purple'>
                      <div
                        className='flex flex-column justify-center'
                        style={{ height: '100%' }}
                      >
                        <div className='menu-login-icon has-text-white'>
                          <div
                            onClick={() =>
                              window.open(
                                SKOOLAS_ACADEMIK_URL,
                                '_blank'
                              )
                            }
                            className='flex flex-column justify-center align-center my-1'
                          >
                            <div
                              className='flex flex-column justify-center align-center hand-pointer hover-login-icon'
                              style={{ height: 90, width: 90 }}
                            >
                              <svg
                                width={36}
                                height={36}
                                fill='#ffffff'
                                viewBox='0 0 24 24'
                              >
                                <title>Akademik</title>
                                <path d='M12,3L1,9L12,15L21,10.09V17H23V9M5,13.18V17.18L12,21L19,17.18V13.18L12,17L5,13.18Z' />
                              </svg>{' '}
                              Akademik
                            </div>
                          </div>
                          <div className='flex flex-column justify-center align-center my-1'>
                            <div
                              className='flex flex-column justify-center align-center hand-pointer active-login-icon hover-login-icon'
                              style={{ height: 90, width: 90 }}
                            >
                              <svg
                                width={36}
                                height={36}
                                fill='#ffffff'
                                viewBox='0 0 24 24'
                              >
                                <title>Portal Sekolah</title>
                                <path d='M16.36,14C16.44,13.34 16.5,12.68 16.5,12C16.5,11.32 16.44,10.66 16.36,10H19.74C19.9,10.64 20,11.31 20,12C20,12.69 19.9,13.36 19.74,14M14.59,19.56C15.19,18.45 15.65,17.25 15.97,16H18.92C17.96,17.65 16.43,18.93 14.59,19.56M14.34,14H9.66C9.56,13.34 9.5,12.68 9.5,12C9.5,11.32 9.56,10.65 9.66,10H14.34C14.43,10.65 14.5,11.32 14.5,12C14.5,12.68 14.43,13.34 14.34,14M12,19.96C11.17,18.76 10.5,17.43 10.09,16H13.91C13.5,17.43 12.83,18.76 12,19.96M8,8H5.08C6.03,6.34 7.57,5.06 9.4,4.44C8.8,5.55 8.35,6.75 8,8M5.08,16H8C8.35,17.25 8.8,18.45 9.4,19.56C7.57,18.93 6.03,17.65 5.08,16M4.26,14C4.1,13.36 4,12.69 4,12C4,11.31 4.1,10.64 4.26,10H7.64C7.56,10.66 7.5,11.32 7.5,12C7.5,12.68 7.56,13.34 7.64,14M12,4.03C12.83,5.23 13.5,6.57 13.91,8H10.09C10.5,6.57 11.17,5.23 12,4.03M18.92,8H15.97C15.65,6.75 15.19,5.55 14.59,4.44C16.43,5.07 17.96,6.34 18.92,8M12,2C6.47,2 2,6.5 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z' />
                              </svg>
                              Portal
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> */}
                    <div className='column'>
                      <div className='media'>
                        <div className='media-content'>
                          <div className='content'>
                            <div className='has-text-centered mb-5'>
                              <img
                                alt='profile-pic'
                                src={LOGO_SCHOOL}
                                style={{
                                  width: 280,
                                  height: 'auto',
                                }}
                              />
                              <p>
                                {/* <span className='has-text-info has-text-weight-bold is-size-5 mt-2'>
                                  {SCHOOL_NAME}
                                </span>
                                <br /> */}
                                <span className='has-text-info has-text-weight-semibold is-size-5 mt-2'>
                                  Admin Web Sekolah
                                </span>
                              </p>
                            </div>
                            <form
                              onSubmit={handleSubmit(handleLogin)}
                              autoComplete='off'
                            >
                              <Field
                                name='username'
                                type='text'
                                component={LoginTextInput}
                                placeholder='Username'
                                autoComplete='new-password'
                                label='Username'
                                icon='account'
                              />
                              <Field
                                name='password'
                                type='password'
                                autoComplete='new-password'
                                component={LoginTextInput}
                                placeholder='Password'
                                label='Password'
                                icon='lock'
                              />
                              <div
                                className='field has-text-centered'
                                style={{ marginTop: 25 }}
                              >
                                {error && (
                                  <p className='help is-danger'>{error}</p>
                                )}
                                <button
                                  disabled={loading}
                                  type='submit'
                                  style={{ marginRight: 10 }}
                                  className={`button is-info is-small is-rounded${
                                    loading ? ' is-loading' : ''
                                  }`}
                                >
                                  <span
                                    className='mr-1 mt-1'
                                    style={
                                      loading ? { visibility: 'hidden' } : {}
                                    }
                                  >
                                    <svg
                                      width={13}
                                      height={13}
                                      fill='#ffffff'
                                      viewBox='0 0 24 24'
                                    >
                                      <path d='M11 7L9.6 8.4L12.2 11H2V13H12.2L9.6 15.6L11 17L16 12L11 7M20 19H12V21H20C21.1 21 22 20.1 22 19V5C22 3.9 21.1 3 20 3H12V5H20V19Z' />
                                    </svg>
                                  </span>
                                  Login
                                </button>
                              </div>
                            </form>
                            <div className='mt-5'></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <p
          className='has-text-centered is-size-7 has-text-dark'
          style={{ marginTop: -14, marginBottom: 8 }}
        >
          Copyright © {new Date().getFullYear()} Skoolas by{' '}
          <span className='has-text-weight-semibold'>Shaktavisi</span>
        </p>
      </section>
    </>
  );
}

export default withRouter(
  connect(mapState, actions)(reduxForm({ form: 'formLogin', validate })(Login))
);
