import {
  CATEGORIES_GET,
  CATEGORY_ADD,
  CATEGORY_EDIT,
  CATEGORY_GET,
  CATEGORY_DELETE,
} from './reduxConstant';
import { createReducer } from '../../../../app/common/utilities/reducerUtils';

const initialState = {
  total: 0,
  search: {
    currentPage: '',
    searchText: '',
    itemPerPage: '',
  },
  items: [],
};

const categoriesGet = (state, payload) => {
  const categories = payload.categories;
  return {
    total: categories.total,
    items: [...categories.items],
    search: { ...categories.search },
  };
};

const categoryAdd = (state, payload) => {
  return {
    total: parseInt(state.total) + 1,
    items: [
      ...state.items.filter((i) => i.id + '' !== payload.category.id + ''),
      payload.category,
    ],
    search: { ...state.search },
  };
};

const categoryEdit = (state, payload) => {
  return {
    total: parseInt(state.total),
    items: [
      ...state.items.filter((i) => i.id + '' !== payload.category.id + ''),
      payload.category,
    ],
    search: { ...state.search },
  };
};

const categoryGet = (state, payload) => {
  if (!state.items.length) {
    return {
      total: parseInt(state.total),
      items: [payload.category],
      search: { ...state.search },
    };
  }
  return {
    total: parseInt(state.total),
    items: [
      ...state.items.filter((i) => i.id + '' !== payload.category.id + ''),
      payload.category,
    ],
    search: { ...state.search },
  };
};

const categoryDelete = (state, payload) => {
  return {
    total: parseInt(state.total) - 1,
    items: [
      ...state.items.filter((i) => i.id + '' !== payload.category.id + ''),
    ],
    search: { ...state.search },
  };
};

export default createReducer(initialState, {
  [CATEGORIES_GET]: categoriesGet,
  [CATEGORY_ADD]: categoryAdd,
  [CATEGORY_EDIT]: categoryEdit,
  [CATEGORY_GET]: categoryGet,
  [CATEGORY_DELETE]: categoryDelete,
});
