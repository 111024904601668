import React from 'react';
import { connect } from 'react-redux';
import { publishTesScores } from './redux/reduxApi';
import { closeModal } from '../../../app/modals/redux/modalActions';
import Modal from '../../../ui/Modal';
import Button from '../../../ui/Button';

const actions = {
  closeModal,
  publishTesScores,
};

function ModalPublishScore({ data, publishTesScores, closeModal }) {
  const { auth, history, id, item } = data;

  function onPublish() {
    publishTesScores(auth, history, id, item);
    closeModal();
  }

  return (
    <Modal variant='info' closeModal={closeModal}>
      <div className='flex justify-around flex-column align-center'>
        <p className='is-size-6 has-text-centered mb-5 mt-5'>
          Apakah Anda yakin ingin mempublish score siswa?
        </p>
        <div className='flex flex-column justify-start'>
          <p className='is-size-6 is-italic mb-2'>
            <span className='tag is-info is-rounded'>Note:</span>
          </p>
          <p className=' is-italic'>
            1. Nilai yang dipublish adalah Nilai Akhir.
          </p>
          <p className=' is-italic'>
            2. Seteleh dipublish siswa akan bisa melihat scorenya.
          </p>
        </div>
      </div>
      <div className='flex justify-end align-center pt-2'>
        <div className='buttons'>
          <Button cvariant='cdelete' onClick={onPublish} />
          <Button cvariant='cback' onClick={closeModal} />
        </div>
      </div>
    </Modal>
  );
}

export default connect(null, actions)(ModalPublishScore);
