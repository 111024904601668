import {
  PAGES_GET,
  PAGE_CATEGORYOPTS_GET,
  PAGE_ADD,
  PAGE_EDIT,
  PAGE_UPLOADFILE,
  PAGE_GET,
  PAGE_DELETE,
} from './reduxConstant';
import { createReducer } from '../../../../app/common/utilities/reducerUtils';

const initialState = {
  total: 0,
  search: {
    currentPage: '',
    searchText: '',
    itemPerPage: '',
  },
  items: [],
  categoryOptions: [],
  uploadStatus: 'finish',
};

const pagesGet = (state, payload) => {
  const pages = payload.pages;
  return {
    ...state,
    total: pages.total,
    items: [...pages.items],
    search: { ...pages.search },
  };
};

const categoryOptsGet = (state, payload) => {
  const categoryOptsRaw = payload.categoryOpts;
  let categoryOpts = [];
  if (categoryOptsRaw.length) {
    categoryOptsRaw.forEach((i) => {
      categoryOpts.push({ key: i.id, text: i.title, value: i.title });
    });
  }
  return {
    ...state,
    categoryOptions: categoryOpts,
  };
};

const pageAdd = (state, payload) => {
  return {
    ...state,
    total: parseInt(state.total) + 1,
    items: [
      ...state.items.filter((i) => i.id + '' !== payload.page.id + ''),
      payload.page,
    ],
    search: { ...state.search },
  };
};

const pageEdit = (state, payload) => {
  return {
    ...state,
    total: parseInt(state.total),
    items: [
      ...state.items.filter((i) => i.id + '' !== payload.page.id + ''),
      payload.page,
    ],
    search: { ...state.search },
  };
};

const pageUploadFile = (state, payload) => {
  let status = payload.status;
  return {
    ...state,
    uploadStatus: status,
  };
};

const pageGet = (state, payload) => {
  return {
    ...state,
    total: parseInt(state.total),
    items: [
      ...state.items.filter((i) => i.id + '' !== payload.page.id + ''),
      payload.page,
    ],
    search: { ...state.search },
  };
};

const pageDelete = (state, payload) => {
  return {
    ...state,
    total: parseInt(state.total) - 1,
    items: [
      ...state.items.filter((i) => i.id + '' !== payload.page.id + ''),
    ],
    search: { ...state.search },
  };
};

export default createReducer(initialState, {
  [PAGES_GET]: pagesGet,
  [PAGE_CATEGORYOPTS_GET]: categoryOptsGet,
  [PAGE_ADD]: pageAdd,
  [PAGE_EDIT]: pageEdit,
  [PAGE_UPLOADFILE]: pageUploadFile,
  [PAGE_GET]: pageGet,
  [PAGE_DELETE]: pageDelete,
});
