import React from 'react';
import ViewDashboard from './ViewDashboard';
import AccountBox from './AccountBox';
import NotebookOutline from './NotebookOutline';
import BookShelf from './BookShelf';
import CalendarMonth from './CalendarMonth';
import AccountGroup from './AccountGroup';
import School from './School';
import AccountCog from './AccountCog';
import PlaylistPlus from './playlist-plus';
import FileDocumentPlusOutline from './FileDocumentPlusOutline';
import FormatListBulletedType from './FormatListBulletedType';
import PanoramaVariantOutline from './PanoramaVariantOutline';

const Icons = ({ color, icon }) => {
  if (icon === 'view-dashboard')
    return (
      <span style={{ paddingTop: 4, marginRight: 10 }}>
        <ViewDashboard size={15} color={color} />
      </span>
    );

  if (icon === 'account-box')
    return (
      <span style={{ paddingTop: 4, marginRight: 10 }}>
        <AccountBox size={15} color={color} />
      </span>
    );

  if (icon === 'panorama-variant-outline')
    return (
      <span style={{ paddingTop: 4, marginRight: 10 }}>
        <PanoramaVariantOutline size={15} color={color} />
      </span>
    );

  if (icon === 'format-list-bulleted-type')
    return (
      <span style={{ paddingTop: 4, marginRight: 10 }}>
        <FormatListBulletedType size={15} color={color} />
      </span>
    );

  if (icon === 'playlist-plus')
    return (
      <span style={{ paddingTop: 4, marginRight: 10 }}>
        <PlaylistPlus size={15} color={color} />
      </span>
    );

  if (icon === 'file-document-plus-outline')
    return (
      <span style={{ paddingTop: 4, marginRight: 10 }}>
        <FileDocumentPlusOutline size={15} color={color} />
      </span>
    );

  if (icon === 'notebook-outline')
    return (
      <span style={{ paddingTop: 4, marginRight: 10 }}>
        <NotebookOutline size={15} color={color} />
      </span>
    );

  if (icon === 'bookshelf')
    return (
      <span style={{ paddingTop: 4, marginRight: 10 }}>
        <BookShelf size={15} color={color} />
      </span>
    );

  if (icon === 'calendar-month')
    return (
      <span style={{ paddingTop: 4, marginRight: 10 }}>
        <CalendarMonth size={15} color={color} />
      </span>
    );

  if (icon === 'account-group')
    return (
      <span style={{ paddingTop: 4, marginRight: 10 }}>
        <AccountGroup size={15} color={color} />
      </span>
    );

  if (icon === 'school')
    return (
      <span style={{ paddingTop: 4, marginRight: 10 }}>
        <School size={15} color={color} />
      </span>
    );

  if (icon === 'account-cog')
    return (
      <span style={{ paddingTop: 4, marginRight: 10 }}>
        <AccountCog size={15} color={color} />
      </span>
    );
  return (
    <span style={{ paddingTop: 4, marginRight: 10 }}>
      <svg width={15} height={15} color={color} viewBox='0 0 24 24'>
        <path d='M10,17L15,12L10,7V17Z' />
      </svg>
    </span>
  );
};

export default Icons;
