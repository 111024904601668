import React from 'react';
import { connect } from 'react-redux';
import { publishTugasScores } from './redux/reduxApi';
import { closeModal } from '../../../app/modals/redux/modalActions';
import Modal from '../../../ui/Modal';
import Button from '../../../ui/Button';

const actions = {
  closeModal,
  publishTugasScores,
};

function ModalPublishScore({ data, publishTugasScores, closeModal }) {
  const { auth, history, id, item, published } = data;
  console.log(published);

  function onPublish() {
    publishTugasScores(auth, history, id, item);
    closeModal();
  }

  return (
    <Modal variant='info' closeModal={closeModal}>
      <div className='flex justify-around flex-column align-center'>
        <p className='is-size-5 has-text-centered mb-5 mt-5'>
          Apakah Anda yakin untuk{' '}
          {published ? (
            'mempublish'
          ) : (
            <span>
              meng-<span className='has-text-danger'>unpublish</span>
            </span>
          )}{' '}
          score siswa?
        </p>
        {published && (
          <div className='flex flex-column justify-start'>
            <p className='is-size-6 is-italic mb-2'>
              <span className='tag is-info is-rounded'>Note:</span>
            </p>
            <p className=' is-italic'>
              1. Nilai yang akan dipublish adalah Nilai Akhir.
            </p>
            <p className=' is-italic'>
              2. Seteleh dipublish siswa akan bisa melihat scorenya.
            </p>
          </div>
        )}
        {!published && (
          <div className='flex flex-column justify-start'>
            <p className='is-size-6 is-italic mb-2'>
              <span className='tag is-info is-rounded'>Note:</span>
            </p>
            <p className='is-italic'>
              1. Seteleh di-unpublish siswa tidak lagi bisa melihat scorenya.
            </p>
          </div>
        )}
      </div>
      <div className='flex justify-end align-center pt-2'>
        <div className='buttons'>
          <Button cvariant='csave' onClick={onPublish} />
          <Button cvariant='cback' onClick={closeModal} />
        </div>
      </div>
    </Modal>
  );
}

export default connect(null, actions)(ModalPublishScore);
