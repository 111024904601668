import {
  MENUWEBS_GET,
  MENUWEB_CATEGORYOPTS_GET,
  MENUWEB_PAGEOPTS_GET,
  MENUWEB_ADD,
  MENUWEB_EDIT,
  MENUWEB_GET,
  MENUWEB_DELETE,
} from './reduxConstant';

export const menuwebsGet = (menuwebs) => {
  return {
    type: MENUWEBS_GET,
    payload: {
      menuwebs,
    },
  };
};

export const categoryOptionsGet = (categoryOpts) => {
  return {
    type: MENUWEB_CATEGORYOPTS_GET,
    payload: {
      categoryOpts,
    },
  };
};

export const pageOptionsGet = (pageOpts) => {
  return {
    type: MENUWEB_PAGEOPTS_GET,
    payload: {
      pageOpts,
    },
  };
};

export const menuwebAdd = (menuweb) => {
  return {
    type: MENUWEB_ADD,
    payload: {
      menuweb,
    },
  };
};

export const menuwebEdit = (menuweb) => {
  return {
    type: MENUWEB_EDIT,
    payload: {
      menuweb,
    },
  };
};

export const menuwebGet = (menuweb) => {
  return {
    type: MENUWEB_GET,
    payload: {
      menuweb,
    },
  };
};

export const menuwebDelete = (menuweb) => {
  return {
    type: MENUWEB_DELETE,
    payload: {
      menuweb,
    },
  };
};
