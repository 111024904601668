import {
  asyncActionFinish,
  asyncActionError,
} from '../../../../app/common/reduxs/async/asyncActions';
import {
  announcementsGet,
  announcementAdd,
  announcementEdit,
  announcementGet,
  announcementUploadFile,
  announcementDelete,
} from './reduxAction';
import { ASYNC_ACTION_START } from '../../../../app/common/reduxs/async/asyncConstant';
import { checkErr } from '../../../../app/common/helpers/checkRes';
import { fetchFunc } from '../../../../app/common/helpers/fetchHelpers';
import { logsFunc } from '../../../../app/common/helpers/othersHelpers';
import { toastr } from 'react-redux-toastr';

export const getAnnouncements = (
  auth,
  history,
  itemPerPage,
  currentPage,
  searchText
) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'getAnnouncements' });
    const formData = new FormData();
    const allDataAccess = auth.authorities.subm.announcement_all;
    formData.append('itemPerPage', itemPerPage ? itemPerPage : 10);
    formData.append('currentPage', currentPage);
    formData.append('searchText', searchText);
    formData.append('allData', allDataAccess);
    try {
      const res = await fetchFunc(
        'gets',
        auth,
        history,
        'announcement/',
        formData,
        dispatch
      );
      const items = res.items;
      const total = res.total;
      const search = {
        currentPage,
        searchText,
        itemPerPage: itemPerPage ? itemPerPage : 10,
      };
      dispatch(announcementsGet({ total, items, search }));
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const addAnnouncement = (auth, history, values) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'addAnnouncement' });
    const formData = new FormData();
    formData.append('title', values.title);
    formData.append('description', values.description);
    formData.append('date', values.date);
    formData.append('day', values.day);
    if (values.group) {
      formData.append('tingkat', values.tingkat);
      formData.append(
        'kelas',
        values.kelas ? JSON.stringify(values.kelas) : undefined
      );
    }
    try {
      const res = await fetchFunc(
        'add',
        auth,
        history,
        'announcement/add/' + values.id,
        formData,
        dispatch
      );
      const announcement = res?.announcement;
      if (announcement) {
        dispatch(announcementAdd(announcement));
        toastr.success('Success', 'Pengumuman berhasil disimpan.');
        if (values.addMedia) {
          history.push('/portal/pengumuman/edit/' + announcement.id);
        } else {
          history.push('/portal/pengumuman/' + announcement.id);
        }
      }
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const getAnnouncement = (auth, history, id) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'getAnnouncement' });
    const formData = new FormData();
    try {
      const res = await fetchFunc(
        'get',
        auth,
        history,
        'announcement/detail/' + id,
        formData,
        dispatch
      );
      const announcement = res.announcement;
      dispatch(announcementGet(announcement));
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const editAnnouncement = (auth, history, values) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'editAnnouncement' });
    const formData = new FormData();
    formData.append('title', values.title);
    formData.append('description', values.description);
    formData.append('date', values.date);
    formData.append('day', values.day);
    formData.append('tingkat', values.tingkat);
    formData.append('kelas', values.group ? JSON.stringify(values.kelas) : '');
    
    try {
      const res = await fetchFunc(
        'edit',
        auth,
        history,
        'announcement/edit/' + values.id,
        formData,
        dispatch
      );
      const announcement = res?.announcement;
      if (announcement) {
        dispatch(announcementEdit(announcement));
        history.push('/portal/pengumuman/' + values.id);
      }
      toastr.success('Success', 'Pengumuman berhasil disimpan.');
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const uploadAnnouncement = (auth, history, values) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'uploadAnnouncement' });
    let file = values.file;
    dispatch(
      announcementUploadFile({
        id: values.id,
        filename: file.name,
        filesize: file.size,
        filetype: file.type,
        status: 'start',
      })
    );
    const formData = new FormData();
    formData.append('id', values.id);
    formData.append('title', values.title);
    formData.append('date', values.date);
    formData.append('description', values.description);
    formData.append('filename', file.name);
    formData.append('filesize', file.size);
    formData.append('filetype', file.type);
    formData.append('file', values.file);
    try {
      const res = await fetchFunc(
        'uploadAttach',
        auth,
        history,
        'announcement/upload/' + values.id,
        formData,
        dispatch
      );
      if (!res) {
        dispatch(asyncActionFinish());
        return;
      }
      dispatch(announcementGet(res.announcement));
      dispatch(
        announcementUploadFile({
          id: values.id,
          filename: file.name,
          filesize: file.size,
          filetype: file.type,
          status: 'finish',
        })
      );
      history.push('/portal/pengumuman/edit/' + values.id);
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const deleteFileAnnouncement = (auth, history, data, file) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'deleteFileAnnouncement' });
    let logs = logsFunc('edit', auth.userid, auth.username, data.logs);
    const formData = new FormData();
    formData.append('announcement', data.announcementId);
    formData.append('filelink', file.filelink);
    formData.append('logs', logs);
    try {
      const res = await fetchFunc(
        'deleteFile',
        auth,
        history,
        'announcement/delete-file/' + data.announcementId,
        formData,
        dispatch
      );
      const announcement = res.announcement;
      dispatch(announcementEdit(announcement));
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const deleteAnnouncement = (auth, history, id) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'deleteAnnouncement' });
    const formData = new FormData();
    try {
      const res = await fetchFunc(
        'delete',
        auth,
        history,
        'announcement/delete/' + id,
        formData,
        dispatch
      );
      const announcement = res.announcement;
      dispatch(announcementDelete(announcement));
      history.push('/portal/pengumuman');
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};
