import React, { Component } from 'react';
import { connect } from 'react-redux';
import { closeModal } from '../../modals/redux/modalActions';

const actions = {
  closeModal,
};

class ModalImagePopup extends Component {
  render() {
    const { data, size, closeModal } = this.props;
    const url = data;
    return (
      <div className='modal is-active'>
        <div className='modal-background' onClick={closeModal}></div>
        <div className='modal-content'>
          <div className='image is-4by3 has-text-centered'>
            <img
              src={url}
              alt='preview'
              style={{
                display: 'block',
                margin: 'auto',
                width: 'auto',
                height: size ? `${size}%` : '100%',
              }}
            />
          </div>
        </div>
        <button
          className='modal-close is-large'
          aria-label='close'
          onClick={closeModal}
        ></button>
      </div>
    );
  }
}

export default connect(null, actions)(ModalImagePopup);
