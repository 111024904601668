import React from 'react';
import { connect } from 'react-redux';
import { statusCategory } from './redux/reduxApi';
import { closeModal } from '../../../app/modals/redux/modalActions';
import Modal from '../../../ui/Modal';
import Button from '../../../ui/Button';

const actions = {
  closeModal,
  statusCategory,
};

function ModalStatus({ data, closeModal, statusCategory }) {
  const { auth, history, item } = data;

  function setStatus() {
    const newValues = { ...item, status: item.status ? false : true };
    statusCategory(auth, history, newValues);
    closeModal();
  }

  return (
    <Modal variant='info' closeModal={closeModal}>
      <p className='my-3 mx-1'>
        Apakah Anda ingin set kategori{' '}
        <span className='has-text-danger has-text-weight-semibold is-capitalized'>
          {item.title}
        </span>{' '}
        menjadi {item.status ? 'Page' : 'Menu'} ?
      </p>
      <div className='flex justify-end align-center pt-2'>
        <div className='buttons'>
          <Button cvariant='csave' onClick={setStatus} />
          <Button cvariant='cback' onClick={closeModal} />
        </div>
      </div>
    </Modal>
  );
}

export default connect(null, actions)(ModalStatus);
