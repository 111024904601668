import {
  asyncActionFinish,
  asyncActionError,
} from '../../../../app/common/reduxs/async/asyncActions';
import {
  tugassGet,
  tugasAdd,
  tugasGet,
  studentAnswerGet,
  tugasEdit,
  tugasDetailScores,
  tugasListScores,
  tugasUploadFile,
  tugasDeleteStudentAnswer,
  tugasDelete,
  tugasResetSearch,
} from './reduxAction';
import { ASYNC_ACTION_START } from '../../../../app/common/reduxs/async/asyncConstant';
import { checkErr } from '../../../../app/common/helpers/checkRes';
import { fetchFunc } from '../../../../app/common/helpers/fetchHelpers';
import { logsFunc } from '../../../../app/common/helpers/othersHelpers';
import { SITE_ADDRESS } from '../../../../app/common/data/siteConfig';
import { saveAs } from 'file-saver';
import { nanoid } from 'nanoid';

export const getTugass = (
  auth,
  history,
  itemPerPage,
  currentPage,
  searchText,
  searchMoreText,
  isReset
) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'getTugass' });
    const formData = new FormData();
    if (isReset === 'reset') {
      dispatch(tugasResetSearch());
    }
    const allDataAccess = auth.authorities.subm.tugas_all;
    formData.append('itemPerPage', itemPerPage ? itemPerPage : 10);
    formData.append('currentPage', currentPage);
    formData.append('searchText', searchText);
    formData.append('allData', allDataAccess);
    formData.append('searchMoreText', JSON.stringify(searchMoreText));
    try {
      const res = await fetchFunc(
        'gets',
        auth,
        history,
        'tugas/',
        formData,
        dispatch
      );
      const items = res.items;
      const total = res.total;
      const search = {
        currentPage,
        searchText,
        itemPerPage: itemPerPage ? itemPerPage : 10,
        ...searchMoreText,
      };
      dispatch(tugassGet({ total, items, search }));
      if (isReset === 'reset') {
        history.push('/pembelajaran/tugas');
      }
      if (isReset === 'resetcopy') {
        history.push('/pembelajaran/tugas/copy');
      }
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const addTugas = (auth, history, values) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'addTugas' });
    let logs = logsFunc('create', auth.userid, auth.username, null);
    const formData = new FormData();
    formData.append('title', values.title);
    formData.append('description', values.description);
    formData.append('questions', JSON.stringify(values.questions));
    formData.append('answers', JSON.stringify(values.answers));
    formData.append('logs', logs);
    try {
      const res = await fetchFunc(
        'edit',
        auth,
        history,
        'tugas/add/' + values.id,
        formData,
        dispatch
      );
      const tugas = res.tugas;
      dispatch(tugasAdd(tugas));
      history.push('/pembelajaran/tugas/edit/' + values.id);
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};
// get all data tugas to be select & copy
export const getTugassCopy = (
  auth,
  history,
  itemPerPage,
  currentPage,
  searchText,
  searchMoreText,
  isReset
) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'getTugassCopy' });
    const formData = new FormData();
    if (isReset === 'reset') {
      dispatch(tugasResetSearch());
    }
    const allDataAccess = auth.authorities.subm.materi_all;
    formData.append('itemPerPage', itemPerPage ? itemPerPage : 10);
    formData.append('currentPage', currentPage);
    formData.append('searchText', searchText);
    formData.append('allData', allDataAccess);
    formData.append('searchMoreText', JSON.stringify(searchMoreText));
    try {
      const res = await fetchFunc(
        'gets',
        auth,
        history,
        'tugas/copy-all/',
        formData,
        dispatch
      );
      const items = res.items;
      const total = res.total;
      const search = {
        currentPage,
        searchText,
        itemPerPage: itemPerPage ? itemPerPage : 10,
        ...searchMoreText,
      };
      dispatch(tugassGet({ total, items, search }));
      if (isReset === 'resetcopy') {
        history.push('/pembelajaran/tugas/copy');
      }
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const copyTugas = (auth, history, values, id) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'copyTugas' });
    let logs = logsFunc('create', auth.userid, auth.username, null);
    const formData = new FormData();
    formData.append('oldId', values.id);
    formData.append('newId', id);
    formData.append('title', 'copy of ' + values.title);
    formData.append('logs', logs);
    try {
      const res = await fetchFunc(
        'edit',
        auth,
        history,
        'tugas/copy/' + values.id,
        formData,
        dispatch
      );
      const tugas = res.tugas;
      dispatch(tugasAdd(tugas));
      history.push('/pembelajaran/tugas/edit/' + id);
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const getTugas = (auth, history, id) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'getTugas' });
    const formData = new FormData();
    try {
      const res = await fetchFunc(
        'get',
        auth,
        history,
        'tugas/detail/' + id,
        formData,
        dispatch
      );
      const tugas = res.tugas;
      dispatch(tugasGet(tugas));
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const editTugas = (auth, history, values) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'editTugas' });
    let logs = logsFunc('edit', auth.userid, auth.username, values.logs);
    const formData = new FormData();
    formData.append('title', values.title);
    formData.append('description', values.description);
    formData.append('questions', JSON.stringify(values.questions));
    formData.append('answers', JSON.stringify(values.answers));
    formData.append('logs', logs);
    try {
      const res = await fetchFunc(
        'edit',
        auth,
        history,
        'tugas/edit/' + values.id,
        formData,
        dispatch
      );
      const tugas = res.tugas;
      dispatch(tugasEdit(tugas));
      history.push('/pembelajaran/tugas/' + values.id);
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const publishTugas = (auth, history, values) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'publishTugas' });
    let logs = logsFunc('edit', auth.userid, auth.username, values.logs);
    const formData = new FormData();
    formData.append('published', values.published);
    formData.append('year', values.year);
    formData.append('sem', values.sem);
    formData.append('jenjang', values.jenjang);
    formData.append('tingkat', values.tingkat);
    formData.append('kelas', values.kelas.toString());
    formData.append('code', values.code);
    formData.append('pelajaran', values.pelajaran);
    formData.append('tags', JSON.stringify(values.tags));
    formData.append('publishDate', values.publishDate);
    formData.append('logs', logs);
    try {
      const res = await fetchFunc(
        'edit',
        auth,
        history,
        'tugas/publish/' + values.id,
        formData,
        dispatch
      );
      const tugas = res.tugas;
      dispatch(tugasEdit(tugas));
      history.push('/pembelajaran/tugas/' + values.id);
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const getStudentAnswer = (auth, history, id) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'getStudentAnswer' });
    const formData = new FormData();
    try {
      const res = await fetchFunc(
        'get',
        auth,
        history,
        'tugas/student-answer/' + id,
        formData,
        dispatch
      );
      const studentAnswer = res.studentAnswer;
      dispatch(studentAnswerGet(studentAnswer));
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const submitTugasScores = (auth, history, id, values) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'submitTugasScores' });
    const formData = new FormData();
    formData.append('scores', JSON.stringify(values));
    try {
      const res = await fetchFunc(
        'edit',
        auth,
        history,
        'tugas/submit-scores/' + id,
        formData,
        dispatch
      );
      const tugas = res.tugas;
      dispatch(tugasEdit(tugas));
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const publishTugasScores = (auth, history, id, values) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'publishTugasScores' });
    const formData = new FormData();
    formData.append('scores', JSON.stringify(values));
    try {
      const res = await fetchFunc(
        'edit',
        auth,
        history,
        'tugas/publish-scores/' + id,
        formData,
        dispatch
      );
      const tugas = res.tugas;
      dispatch(tugasEdit(tugas));
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const saveAnswerKey = (auth, history, id, values) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'saveAnswerKey' });
    const formData = new FormData();
    formData.append('answers', JSON.stringify(values));
    try {
      const res = await fetchFunc(
        'edit',
        auth,
        history,
        'tugas/save-answer-key/' + id,
        formData,
        dispatch
      );
      const tugas = res.tugas;
      dispatch(tugasEdit(tugas));
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const shareAnswerKey = (auth, history, id, values) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'shareAnswerKey' });
    const formData = new FormData();
    formData.append('values', JSON.stringify(values));
    try {
      const res = await fetchFunc(
        'edit',
        auth,
        history,
        'tugas/share-answer-key/' + id,
        formData,
        dispatch
      );
      const tugas = res.tugas;
      dispatch(tugasEdit(tugas));
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const deleteShareAnswerKey = (auth, history, id) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'deleteShareAnswerKey' });
    const formData = new FormData();
    formData.append('id', id);
    try {
      const res = await fetchFunc(
        'edit',
        auth,
        history,
        'tugas/delete-share-answer-key/' + id,
        formData,
        dispatch
      );
      const tugas = res.tugas;
      dispatch(tugasEdit(tugas));
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const importTugas = (auth, history, values) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'importTugas' });
    try {
      let questions = [];
      let answers = [];
      for (let i = 0; i < values.numberOfQuestion; i++) {
        let item = values.data[i];
        let key = nanoid();
        let tipe = item.Tipe.toLowerCase();
        let questionType;
        if (tipe === 'pilihan') {
          questionType = 'optionQuestion';
        } else if (tipe === 'b/s') {
          questionType = 'rightWrongQuestion';
        } else if (tipe === 'singkat') {
          questionType = 'shortQuestion';
        } else {
          questionType = 'longQuestion';
        }
        let newQuestion = {
          no: item.No,
          key: key,
          questionType: questionType,
          question: item.Pertanyaan,
        };
        newQuestion.options =
        tipe === 'pilihan' || tipe === 'b/s' ? item.Pilihan.split('\r\n') : [];
        questions = [...questions, newQuestion];
        let newAnswer = {
          no: item.No,
          key: key,
          answer: item.Jawaban,
          explanation: item.Penjelasan,
        };
        answers = [...answers, newAnswer];
      }
      let logs = logsFunc('create', auth.userid, auth.username, null);
      const formData = new FormData();
      formData.append('id', nanoid(32));
      formData.append('title', values.title);
      formData.append('description', values.description);
      formData.append('questions', JSON.stringify(questions));
      formData.append('answers', JSON.stringify(answers));
      formData.append('logs', logs);
      const res = await fetchFunc(
        'add',
        auth,
        history,
        'tugas/import/',
        formData,
        dispatch
      );
      const importResult = res.importResult;
      history.push('/pembelajaran/tugas/edit/' + importResult.id);
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const getDetailTugasScores = (auth, history, id, values) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'getDetailTugasScores' });
    const formData = new FormData();
    formData.append('year', values.year);
    formData.append('sem', values.sem);
    formData.append('kelas', values.kelas);
    formData.append('pelajaran', values.pelajaran);
    formData.append('code', values.kodePelajaran);
    try {
      const res = await fetchFunc(
        'get',
        auth,
        history,
        'tugas/detail-scores/' + id,
        formData,
        dispatch
      );
      const detailScores = res.detailScores;
      dispatch(tugasDetailScores(detailScores));
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const getListTugasScores = (auth, history, id, values) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'getListTugasScores' });
    const formData = new FormData();
    formData.append('idx', values.idx);
    formData.append('typeScoring', values.typeScoring);
    formData.append('detailScoring', values.detailScoring);
    try {
      const res = await fetchFunc(
        'get',
        auth,
        history,
        'tugas/list-scores/' + id,
        formData,
        dispatch
      );
      const listScores = res.listScores;
      dispatch(tugasListScores(listScores));
      dispatch(asyncActionFinish());
      // return listScores;
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const sendListTugasScores = (auth, history, id, values) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'sendListTugasScores' });
    const formData = new FormData();
    formData.append('idx', values.idx);
    formData.append('typeScoring', values.typeScoring);
    formData.append('detailScoring', values.detailScoring);
    formData.append('rowScores', values.rowScores);
    formData.append('kelas', values.kelas);
    try {
      const res = await fetchFunc(
        'edit',
        auth,
        history,
        'tugas/send-list-scores/' + id,
        formData,
        dispatch
      );
      const tugas = res.tugas;
      dispatch(tugasEdit(tugas));
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const deleteSyncScores = (auth, history, id, values) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'deleteSyncScores' });
    const formData = new FormData();
    formData.append('kelas', values.kelas);
    try {
      const res = await fetchFunc(
        'edit',
        auth,
        history,
        'tugas/delete-sync-scores/' + id,
        formData,
        dispatch
      );
      const tugas = res.tugas;
      dispatch(tugasEdit(tugas));
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const uploadImage = (auth, history, values) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'uploadImage' });
    dispatch(
      tugasUploadFile({
        key: values.keyItem,
        filename: values.filename,
        filesize: values.filesize,
        filetype: values.filetype,
        status: 'start',
      })
    );
    let logAction = values.logs ? 'edit' : 'create';
    let logs = logsFunc(logAction, auth.userid, auth.username, values.logs);
    const formData = new FormData();
    formData.append('title', values.title);
    formData.append('description', values.description);
    formData.append('keyItem', values.keyItem);
    formData.append('questions', JSON.stringify(values.questions));
    formData.append('answers', JSON.stringify(values.answers));
    formData.append('file', values.file);
    formData.append('filename', values.filename);
    formData.append('filesize', values.filesize);
    formData.append('filetype', values.filetype);
    formData.append('logs', logs);
    try {
      const res = await fetchFunc(
        'uploadAttach',
        auth,
        history,
        'tugas/upload-image/' + values.id,
        formData,
        dispatch
      );
      const tugas = res.tugas;
      dispatch(tugasEdit(tugas));
      dispatch(
        tugasUploadFile({
          key: values.keyItem,
          filename: values.filename,
          filesize: values.filesize,
          filetype: values.filetype,
          status: 'finish',
        })
      );
      dispatch(asyncActionFinish());
      history.push('/pembelajaran/tugas/edit/' + values.id);
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const viewImage = (auth, history, values, file) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'viewImage' });
    const formData = new FormData();
    formData.append('userId', auth.userId);
    formData.append('username', auth.username);
    formData.append('link', file.link);
    try {
      const fetchData = await fetch(
        SITE_ADDRESS + 'tugas/download-file/' + values.id,
        {
          method: 'POST',
          body: formData,
          headers: {
            Authorization: 'Bearer ' + auth.token,
          },
        }
      );
      const blob = await fetchData.blob();
      saveAs(blob, file.link.split('/')[file.link.split('/').length - 1]);
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const downloadFile = (auth, history, values, file) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'downloadFile' });
    const formData = new FormData();
    formData.append('userId', auth.userId);
    formData.append('username', auth.username);
    formData.append('filelink', file.filelink);
    try {
      const fetchData = await fetch(
        SITE_ADDRESS + 'tugas/download-file/' + values.id,
        {
          method: 'POST',
          body: formData,
          headers: {
            Authorization: 'Bearer ' + auth.token,
          },
        }
      );
      const blob = await fetchData.blob();
      saveAs(
        blob,
        file.filelink.split('/')[file.filelink.split('/').length - 1]
      );
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const deleteImage = (auth, history, values) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'deleteImage' });
    let logs = logsFunc('edit', auth.userid, auth.username, values.logs);
    const formData = new FormData();
    formData.append('questionKey', values.key);
    formData.append('link', values.picture.filelink);
    formData.append('logs', logs);
    try {
      const res = await fetchFunc(
        'deleteFile',
        auth,
        history,
        'tugas/delete-image/' + values.id,
        formData,
        dispatch
      );
      const tugas = res.tugas;
      dispatch(tugasEdit(tugas));
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const getSetting = (auth, history, id) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'getSetting' });
    // const formData = new FormData();
    try {
      // const res = await fetchFunc(
      //   'get',
      //   auth,
      //   history,
      //   'tugas/setting/',
      //   formData,
      //   dispatch
      // );
      // const tugas = res.tugas;
      // dispatch(tugasGet(tugas));
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const settingTugas = (auth, history, values) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'settingTugas' });
    let logs = logsFunc('edit', auth.userid, auth.username, values.logs);
    const formData = new FormData();
    formData.append('title', values.title);
    formData.append('description', values.description);
    formData.append('questions', JSON.stringify(values.questions));
    formData.append('answers', JSON.stringify(values.answers));
    formData.append('logs', logs);
    try {
      const res = await fetchFunc(
        'edit',
        auth,
        history,
        'tugas/setting/',
        formData,
        dispatch
      );
      const tugas = res.tugas;
      dispatch(tugasEdit(tugas));
      history.push('/pembelajaran/tugas/' + values.id);
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const deleteTugasStudentAnswer = (auth, history, tugasId, studentTugasId) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'deleteTugasStudentAnswer' });
    const formData = new FormData();
    formData.append('tugasId', tugasId);
    formData.append('studentTugasId', studentTugasId);
    try {
      const res = await fetchFunc(
        'delete',
        auth,
        history,
        'tugas/delete-student-answer/' + studentTugasId,
        formData,
        dispatch
      );
      const tugas = res.tugas;
      dispatch(tugasDeleteStudentAnswer(tugas));
      history.push('/pembelajaran/tugas/penilaian/' + tugasId);
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const deleteTugasAllStudentAnswer = (auth, history, id) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'deleteTugasAllStudentAnswer' });
    const formData = new FormData();
    try {
      await fetchFunc(
        'delete',
        auth,
        history,
        'tugas/delete-all-student-answer/' + id,
        formData,
        dispatch
      );
      dispatch(tugasDeleteStudentAnswer());
      history.push('/pembelajaran/tugas/' + id);
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const deleteTugas = (auth, history, id) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'deleteTugas' });
    const formData = new FormData();
    try {
      const res = await fetchFunc(
        'delete',
        auth,
        history,
        'tugas/delete/' + id,
        formData,
        dispatch
      );
      const tugas = res.tugas;
      dispatch(tugasDelete(tugas));
      history.push('/pembelajaran/tugas');
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};
